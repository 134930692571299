import React from 'react'
import styled from 'styled-components'
import { db } from './firebase'
import grades from './grades'
import Button from './Button'
import Field from './Field'
import colors from './colors'

const Form = styled.form`
  background-color: ${colors.primary};
  color: ${colors.background};
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Fields = styled.div`
  width: 500px;
  max-width: 100%;
  padding: 20px;
  margin: auto;
`

const Row = styled.div`
  margin: 10px 0;
`

const defaultValues = {
  wellProtected: false,
  beginnerFriendly: false,
  topropeFriendly: false,
  grade: 0,
  stars: 0,
  area: 'Kullaberg'
}

function CreateRoute({ user, setScreen }) {
  const [route, setRoute] = React.useState({ ...defaultValues })

  function handeFieldChange(e) {
    setRoute({
      ...route,
      [e.target.name]: e.target.value
    })
  }

  function handleCheckboxChange(e) {
    setRoute({
      ...route,
      [e.target.name]: e.target.checked
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    db.collection('routes').add({
      ...route,
      tickedBy: [],
      createdBy: user.uid
    })
    setRoute({ ...defaultValues })
    setScreen('list')
  }

  console.log('rendering route', route)

  return (
    <Form onSubmit={handleSubmit}>
      <Fields>
        <h2>Ny led</h2>
        <Field
          label="Namn"
          type="text"
          name="name"
          value={route.name || ''}
          onChange={handeFieldChange}
        />
        <Field
          label="Klippa"
          type="text"
          name="crag"
          value={route.crag || ''}
          onChange={handeFieldChange}
        />
        <div>
          <label>
            Område{' '}
            <select name="area" value={route.area} onChange={handeFieldChange}>
              <option value="Kullaberg">Kullaberg</option>
              <option value="Bohuslän">Bohuslän</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Grad{' '}
            <select
              name="grade"
              value={route.grade}
              onChange={handeFieldChange}
            >
              {grades.map((grade, i) => (
                <option key={i} value={i}>
                  {grade}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label>
            Välsäkrad{' '}
            <input
              type="checkbox"
              name="wellProtected"
              checked={route.wellProtected}
              onChange={handleCheckboxChange}
            />
          </label>
        </div>
        <div>
          <label>
            Bra nybörjarled{' '}
            <input
              type="checkbox"
              name="beginnerFriendly"
              checked={route.beginnerFriendly}
              onChange={handleCheckboxChange}
            />
          </label>
        </div>
        <div>
          <label>
            Bra topprepsled{' '}
            <input
              type="checkbox"
              name="topropeFriendly"
              checked={route.topropeFriendly}
              onChange={handleCheckboxChange}
            />
          </label>
        </div>
        <div>
          <label>
            Stjärnor{' '}
            <select
              name="stars"
              value={route.stars}
              onChange={handeFieldChange}
            >
              <option value={0}>inga</option>
              <option value={1}>⭐️</option>
              <option value={2}>⭐️⭐️</option>
              <option value={3}>⭐️⭐️⭐️</option>
            </select>
          </label>
        </div>
        <Row>
          <Button
            inverted
            fullWidth
            type="button"
            onClick={() => setScreen('list')}
          >
            Avbryt
          </Button>
        </Row>
        <Row>
          <Button inverted fullWidth type="submit">
            Spara
          </Button>
        </Row>
      </Fields>
    </Form>
  )
}

export default CreateRoute
