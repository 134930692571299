import React from 'react'
import styled from 'styled-components'
import firebase from './firebase'
import CreateRoute from './CreateRoute'
import ListRoutes from './ListRoutes'
import Filters from './Filters'
import colors from './colors'
import { FilterProvider } from './FilterContext'

function handleLogout() {
  firebase
    .auth()
    .signOut()
    .then(function() {
      console.log('logged out')
    })
    .catch(function(err) {
      console.log('logout error', err)
    })
}

const TopBar = styled.div`
  width: 100%;
  background-color: ${colors.text};
  color: ${colors.background};
  font-size: 12px;
  display: flex;
  justify-content: space-between;
`

const UserItem = styled.div`
  display: flex;
  align-items: center;
`

const UserImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`

const LogoutButton = styled.button`
  background-color: ${colors.primary};
  border: 0;
  font-family: 'Roboto Mono';
  font-weight: bold;
`

function Authenticated({ user }) {
  const [screen, setScreen] = React.useState('list')
  return (
    <FilterProvider>
      {screen === 'list' && <ListRoutes user={user} setScreen={setScreen} />}
      {screen === 'create' && <CreateRoute user={user} setScreen={setScreen} />}
      {screen === 'filters' && <Filters user={user} setScreen={setScreen} />}
    </FilterProvider>
  )
}

export default Authenticated
