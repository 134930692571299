import React from 'react'
import firebase, { googleProvider } from './firebase'
import styled from 'styled-components'
import buttonImage from './assets/btn_google_signin_light_normal_web@2x.png'
import buttonActiveImage from './assets/btn_google_signin_light_pressed_web@2x.png'
import buttonFocusImage from './assets/btn_google_signin_light_focus_web@2x.png'

const GoogleButton = styled.a`
  display: inline-block;
  width: 191px;
  height: 46px;
  background-image: url(${buttonImage});
  background-size: contain;
  &:focus {
    background-image: url(${buttonFocusImage});
  }
  &:active {
    background-image: url(${buttonActiveImage});
  }
`

function signInWithGoogle(e) {
  e.preventDefault()
  firebase.auth().signInWithRedirect(googleProvider)
}

function SignInWithGoogle() {
  return <GoogleButton href="#signin" onClick={signInWithGoogle} />
}

export default SignInWithGoogle
