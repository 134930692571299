import React from 'react'

function Field({ label, ...rest }) {
  return (
    <div>
      <label>
        {label} <input {...rest} />
      </label>
    </div>
  )
}

export default Field
