import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBNQmDC9DID4YyO7S-k2W0lgpzkytYSy44',
  authDomain: 'skitapa-ticklist.firebaseapp.com',
  databaseURL: 'https://skitapa-ticklist.firebaseio.com',
  projectId: 'skitapa-ticklist',
  storageBucket: 'skitapa-ticklist.appspot.com',
  messagingSenderId: '489237761312',
  appId: '1:489237761312:web:beca460f188d52ea35db45'
}

firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
export const googleProvider = new firebase.auth.GoogleAuthProvider()

export default firebase
