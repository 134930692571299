import React from 'react'
import styled from 'styled-components'
import firebase, { db } from './firebase'
import grades from './grades'
import Button from './Button'
import FilterContext from './FilterContext'

function useRoutes({ user }) {
  const [routes, setRoutes] = React.useState([])
  const {
    wellProtected,
    beginnerFriendly,
    topropeFriendly,
    area,
    ticks,
    orderBy
  } = React.useContext(FilterContext)
  React.useEffect(() => {
    let collectionRef = db.collection('routes')
    if (area) {
      collectionRef = collectionRef.where('area', '==', area)
    }
    if (wellProtected) {
      collectionRef = collectionRef.where('wellProtected', '==', true)
    }
    if (beginnerFriendly) {
      collectionRef = collectionRef.where('beginnerFriendly', '==', true)
    }
    if (topropeFriendly) {
      collectionRef = collectionRef.where('topropeFriendly', '==', true)
    }

    if (ticks === 'ticked') {
      collectionRef = collectionRef.where(
        'tickedBy',
        'array-contains',
        user.uid
      )
    }

    const [field, direction] = orderBy.split('.')
    collectionRef.orderBy(field, direction).onSnapshot(querySnapshot => {
      const routes = []
      querySnapshot.forEach(function(doc) {
        routes.push({ id: doc.id, ...doc.data() })
      })
      setRoutes(routes)
    })
  }, [orderBy])
  return { routes, orderBy }
}

const Item = styled.div`
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  &:last-child {
    border: 0;
  }
`

const Info = styled.div`
  margin-left: 20px;
`

const Heading = styled.h3`
  font-size: 16px;
  margin: 0;
`

const Meta = styled.p`
  font-size: 14px;
  margin: 0;
`

const ButtonContainer = styled.div`
  margin: 20px;
  display: flex;
  & > * {
    margin: 0 10px;
  }
`

function ListRoutes({ user, setScreen }) {
  const { routes } = useRoutes({ user })

  const { ticks: ticksFilter } = React.useContext(FilterContext)

  const handleTick = id => e => {
    if (e.target.checked) {
      db.collection('routes')
        .doc(id)
        .update({
          tickedBy: firebase.firestore.FieldValue.arrayUnion(user.uid)
        })
    } else {
      db.collection('routes')
        .doc(id)
        .update({
          tickedBy: firebase.firestore.FieldValue.arrayRemove(user.uid)
        })
    }
  }

  const removeRoute = id => () => {
    db.collection('routes')
      .doc(id)
      .delete()
  }

  return (
    <div>
      <ButtonContainer>
        <Button fullWidth onClick={() => setScreen('filters')}>
          Filter
        </Button>
        <Button fullWidth onClick={() => setScreen('create')}>
          Ny led
        </Button>
      </ButtonContainer>
      {routes.map((route, i) => {
        let stars = ''
        const tickedByMe = !!route.tickedBy && route.tickedBy.includes(user.uid)
        if (tickedByMe && ticksFilter === 'unticked') {
          return null
        }
        for (let i = 1; i <= route.stars; i++) {
          stars = stars + '⭐️'
        }
        return (
          <Item key={i}>
            <input
              type="checkbox"
              checked={tickedByMe}
              onChange={handleTick(route.id)}
            />
            <Info>
              <Heading>
                <strong>{route.name}</strong> {grades[route.grade]} {stars}
              </Heading>
              <Meta>
                {route.crag} ({route.area}){route.wellProtected && ' 👍'}
                {route.beginnerFriendly && ' 👶'}
                {route.topropeFriendly && ' 🔝'}{' '}
                {route.tickedBy ? route.tickedBy.length : 0} ticks
              </Meta>
            </Info>
          </Item>
        )
      })}
    </div>
  )
}

export default ListRoutes
