const grades = [
  '3-',
  '3',
  '3+',
  '4-',
  '4',
  '4+',
  '5-',
  '5',
  '5+',
  '6-',
  '6',
  '6+'
]

export default grades
