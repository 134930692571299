import React from 'react'
import SignInWithGoogle from './SignInWithGoogle'
import Fullscreen from './Fullscreen'

function Login() {
  return (
    <Fullscreen>
      <SignInWithGoogle />
    </Fullscreen>
  )
}

export default Login
