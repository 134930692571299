import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import Field from './Field'
import FilterContext from './FilterContext'
import colors from './colors'

const Container = styled.form`
  background-color: ${colors.primary};
  color: ${colors.background};
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Fields = styled.div`
  width: 500px;
  max-width: 100%;
  padding: 20px;
  margin: auto;
`

function Filters({ setScreen }) {
  const {
    orderBy,
    setOrderBy,
    area,
    setArea,
    ticks,
    setTicks,
    wellProtected,
    setWellProtected,
    beginnerFriendly,
    setBeginnerFriendly,
    topropeFriendly,
    setTopropeFriendly
  } = React.useContext(FilterContext)
  const handleOrderByChange = e => setOrderBy(e.target.value)
  const handleAreaChange = e => setArea(e.target.value || null)
  const handleWellProtectedChange = e => setWellProtected(e.target.checked)
  const handleBeginnerFriendlyChange = e =>
    setBeginnerFriendly(e.target.checked)
  const handleTopropeFriendlyChange = e => setTopropeFriendly(e.target.checked)
  const handleTicksChange = e => setTicks(e.target.value || null)

  return (
    <Container>
      <Fields>
        <h2>Filter</h2>
        <div>
          <label>
            Sortera{' '}
            <select value={orderBy} onChange={handleOrderByChange}>
              <option value="grade.asc">Lägsta grad</option>
              <option value="grade.desc">Högsta grad</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Område{' '}
            <select value={area || ''} onChange={handleAreaChange}>
              <option value="">Alla</option>
              <option value="Kullaberg">Kullaberg</option>
              <option value="Bohuslän">Bohuslän</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Ticks{' '}
            <select value={ticks || ''} onChange={handleTicksChange}>
              <option value="">Alla</option>
              <option value="ticked">Bara tickade</option>
              <option value="unticked">Bara otickade</option>
            </select>
          </label>
        </div>
        <Field
          type="checkbox"
          label="Bra nybörjarled"
          checked={beginnerFriendly}
          onChange={handleBeginnerFriendlyChange}
        />
        <Field
          type="checkbox"
          label="Välsäkrad"
          checked={wellProtected}
          onChange={handleWellProtectedChange}
        />
        <Field
          type="checkbox"
          label="Bra topprepsled"
          checked={topropeFriendly}
          onChange={handleTopropeFriendlyChange}
        />
        <Button
          inverted
          fullWidth
          type="button"
          onClick={() => setScreen('list')}
        >
          Stäng
        </Button>
      </Fields>
    </Container>
  )
}

export default Filters
