import React from 'react'
import firebase from './firebase'
import Authenticated from './Authenticated'
import Login from './Login'
import Fullscreen from './Fullscreen'
import { db } from './firebase'

function useFirebaseAuth() {
  const [user, setUser] = React.useState(null)
  const [authResolved, setAuthResolved] = React.useState(false)
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection('users')
          .doc(user.uid)
          .set({
            name: user.displayName,
            photo: user.photoURL
          })
        setUser(user)
      } else {
        setUser(null)
      }
      setAuthResolved(true)
    })
  }, [])
  return { user, authResolved }
}

function App() {
  const { user, authResolved } = useFirebaseAuth()
  if (!authResolved) {
    return (
      <Fullscreen>
        <p>Loading...</p>
      </Fullscreen>
    )
  }

  if (user) {
    return <Authenticated user={user} />
  }

  return <Login />
}

export default App
