import React from 'react'

const FilterContext = React.createContext()

const initialFilters = localStorage.getItem('filters')
  ? JSON.parse(localStorage.getItem('filters'))
  : {
      wellProtected: false,
      beginnerFriendly: false,
      topropeFriendly: false,
      area: null,
      orderBy: 'grade.asc',
      ticks: null
    }

export const FilterProvider = props => {
  const [wellProtected, setWellProtected] = React.useState(
    initialFilters.wellProtected
  )
  const [beginnerFriendly, setBeginnerFriendly] = React.useState(
    initialFilters.beginnerFriendly
  )
  const [topropeFriendly, setTopropeFriendly] = React.useState(
    initialFilters.topropeFriendly
  )
  const [area, setArea] = React.useState(initialFilters.area)
  const [orderBy, setOrderBy] = React.useState(initialFilters.orderBy)
  const [ticks, setTicks] = React.useState(initialFilters.ticks)
  React.useEffect(() => {
    localStorage.setItem(
      'filters',
      JSON.stringify({
        wellProtected,
        beginnerFriendly,
        topropeFriendly,
        area,
        orderBy,
        ticks
      })
    )
  }, [wellProtected, beginnerFriendly, topropeFriendly, area, orderBy, ticks])
  return (
    <FilterContext.Provider
      {...props}
      value={{
        wellProtected,
        setWellProtected,
        beginnerFriendly,
        setBeginnerFriendly,
        topropeFriendly,
        setTopropeFriendly,
        area,
        setArea,
        orderBy,
        setOrderBy,
        ticks,
        setTicks
      }}
    />
  )
}

export default FilterContext
