import styled from 'styled-components'
import colors from './colors'

const Button = styled.button`
  font-family: 'Roboto Mono';
  appearance: none;
  background: ${({ inverted }) =>
    inverted ? colors.background : colors.primary};
  padding: 10px 20px;
  border: 0;
  border-radius: 100px;
  color: ${({ inverted }) => (inverted ? colors.primary : colors.background)};
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'initial')};
`

export default Button
